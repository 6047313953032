<template>
    <v-data-table
        :headers="headers"
        :items="feedbacks"
        class="elevation-1"
        disable-sort
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Заявки</v-toolbar-title>
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
            
    </v-data-table>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';

    import { priceFormated } from '../../helpers';

    export default {
        name: 'FeedbacksList',
        components: {
        },
        data: () => ({
            priceFormated,
            headers: [
                { text: 'Дата', value: 'createdAt' },
                { text: 'Имя', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Телефон', value: 'phone' },
                { text: 'utm_source', value: 'utm_source' },
                { text: 'utm_medium', value: 'utm_medium' },
                { text: 'utm_campaign', value: 'utm_campaign' },
            ],
            updateListTimerId: null
        }),
        computed: {
            ...mapState('feedbacks', {
                feedbacks: state => state.entities
            }),
        },
        async mounted() {
            this.fetch();
        },
        methods: {
            async fetch() {
                await store.dispatch('feedbacks/fetch', {});
            },
        }
    }
</script>